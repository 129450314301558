import React from "react"
import styled from "styled-components"
import { graphql, Link, useStaticQuery } from "gatsby"
import { getProperty, slugify } from "../../lib/utils"

import Breadcrumb from "../../theme/breadcrumb"
import LinkWrapper from "../../lib/link-wrapper"

import PhoneIcon from "../../images/icons/feather-phone"
import PinIcon from "../../images/icons/feather-map-pin"
import MailIcon from "../../images/icons/feather-mail"

const ContactUsWrapperMulti = ({ className = "", pageContext }) => {
  const contactUsMapQueryData = useStaticQuery(CONTACT_US_QUERY)
  const branches = contactUsMapQueryData?.allSeaBranch?.nodes || []
  return (
    <>
      <Breadcrumb
        breadcrumb={getProperty(pageContext, ["breadcrumb", "crumbs"], [])}
      />
      <h1 style={{ marginTop: "50px" }}>Contact us</h1>
      <ContactUsTopContentStyling className={className}>
        <BranchesSectionStyling>
          {branches.map((branch, branch_i) => {
            const name = branch?.contact_details?.name
            const phones = branch?.contact_details?.telephones
            const mail = branch?.contact_details?.contact_email
            const address = branch?.address?.street_address
            const addressLink = branch?.address?.address_link
            return !name ? null : (
              <BranchBoxStyling key={branch_i}>
                <LinkWrapper to={`/contact-us/${slugify(name)}/`}>
                  <h4>
                    {`${name}  `}
                    <span>&#8594;</span>
                  </h4>
                </LinkWrapper>
                <BranchInfoRowStyling>
                  <PhoneIcon />
                  <a href={`tel:${phones[0].telephone_number}`}>
                    {phones[0].telephone_number}
                  </a>
                </BranchInfoRowStyling>
                {!mail ? null : (
                  <BranchInfoRowStyling>
                    <MailIcon />
                    <a href={`mailto:${mail}`}>{mail}</a>
                  </BranchInfoRowStyling>
                )}
                {!address ? null : (
                  <BranchInfoRowStyling>
                    <PinIcon />
                    <a
                      href={addressLink || "#"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {address}
                    </a>
                  </BranchInfoRowStyling>
                )}
              </BranchBoxStyling>
            )
          })}
        </BranchesSectionStyling>
      </ContactUsTopContentStyling>
    </>
  )
}

export default ContactUsWrapperMulti

const ContactUsTopContentStyling = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 3rem auto;
  justify-content: center;
`

const BranchInfoRowStyling = styled.div`
  svg {
    width: 15px;
    height: 15px;
    vertical-align: top;
    margin-top: 0.35rem;
    g,
    path {
      stroke: ${({ theme }) => theme.colors.brandPrimary};
    }
  }
  a {
    padding-left: 45px;
    display: inline-block;
    width: calc(100% - 15px);
    vertical-align: top;
    line-height: 1.6rem;
    white-space: pre-line;

    &:nth-child(2) {
      padding-left: 30px;
    }
  }
  &:not(:last-child) a {
    padding-bottom: 8px;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    a {
      padding-left: 25px;
      padding-bottom: 8px;

      &:nth-child(2) {
        padding-left: 10px;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    a {
      padding-left: 35px;

      &:nth-child(2) {
        padding-left: 20px;
      }
    }
    &:last-child a {
      padding-bottom: 0;
    }
  }
`
const BranchBoxStyling = styled.div`
  background-color: ${({ theme }) => theme.colors.lightgrey1};
  display: block;
  padding: 30px;
  max-width: 400px;
  margin-right: 20px;

  h4 {
    padding-bottom: 20px;
    span {
      margin-left: 5px;
      transition: margin-left 0.3s ease-out;
    }
    &:hover {
      span {
        margin-left: 15px;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    max-width: 32%;
    width: 100%;
    padding: 15px;
    margin-bottom: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    max-width: 100%;
    width: 100%;
    padding: 30px;
    margin-bottom: 20px;
  }
`
const BranchesSectionStyling = styled.div`
  display: flex;
  width: 100%;
  margin-right: 20px;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    max-width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-right: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    flex-direction: column;
    justify-content: space-evenly;
  }
`

// ===============
//      QUERY
// ===============

const CONTACT_US_QUERY = graphql`
  {
    seaSiteInfo {
      site_branches {
        contact_details {
          contact_email
          name
          telephones {
            telephone_label
            telephone_number
          }
        }
        service_hours {
          days
          hours
        }
        address {
          address_link
          google_maps_address
          street_address
        }
      }
    }
    allSeaBranch {
      nodes {
        slug
        address {
          address_link
          google_maps_address
          street_address
        }
        contact_details {
          contact_email
          name
          telephones {
            telephone_label
            telephone_number
          }
        }
        service_hours {
          days
          hours
        }
        shipping_coordinates {
          appear_on_contact_us
          does_collections
          does_deliveries
          latitude
          longitude
          shipping_estimate
        }
      }
    }
  }
`
