import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../theme/layout"
import SEO from "../components/header/seo"
import ContactUsWrapperMulti from "../components/contact-us/contact-us-wrapper-multi"
import ContactUsWrapperSingle from "../components/contact-us/contact-us-wrapper-single"

const ContactUsPage = ({ pageContext, location }) => {
  const contactUsQueryData = useStaticQuery(CONTACT_US_QUERY)
  const branches = contactUsQueryData?.allSeaBranch?.nodes || []
  return (
    <Layout pathContext={pageContext} location={location}>
      <SEO title={"Contact us | Nutribrands"} location={location} />
      {branches.length < 2 ? (
        <ContactUsWrapperSingle
          contactInfo={branches[0]}
          className="page-fullwidth-section"
        ></ContactUsWrapperSingle>
      ) : (
        <ContactUsWrapperMulti
          pageContext={pageContext}
        ></ContactUsWrapperMulti>
      )}
    </Layout>
  )
}

export default ContactUsPage

// ===============
//      QUERY
// ===============
const CONTACT_US_QUERY = graphql`
  {
    allSeaBranch {
      nodes {
        slug
        address {
          address_link
          google_maps_address
          street_address
        }
        contact_details {
          contact_email
          name
          telephones {
            telephone_label
            telephone_number
          }
        }
        service_hours {
          days
          hours
        }
        shipping_coordinates {
          appear_on_contact_us
          does_collections
          does_deliveries
          latitude
          longitude
          shipping_estimate
        }
      }
    }
  }
`
